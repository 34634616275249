import { createBrowserHistory } from "history";
import { Router, Route, Routes } from "react-router-dom";
import Landing from "./components/Landing";
import Action from "./components/Action";
import "./index.css";
const browserHistory = createBrowserHistory();

function App() {
  return (
    <Router location={browserHistory.location} history={browserHistory}>
      <div className="App">
        <Routes>
          <Route
            exact
            path="/"
            element={<Landing />}
            history={browserHistory}
          />
          <Route
            exact
            path="/action"
            element={<Action history={browserHistory} />}
            history={browserHistory}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
