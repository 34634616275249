import React from "react";

const Landing = (props) => {
  return (
    <>
      <div className="bg-gradient-to-r w-full from-bobbllBackground-1 to-bobbllBackground-2">
        <div className="min-h-[100vh]  m-0  grid place-items-center">
          <div
            className="bg-white bg-opacity-60 h-[80vh]  w-11/12 rounded-xl p-10	sm:p-0"
            style={{ backdropFilter: "blur(20px)" }}
          >
            <div className="flex items-center justify-center">
              <div className="text-3xl mt-16">
                <p>Sign In - {process.env.REACT_APP_CUSTOM_NODE_ENV}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Landing;
