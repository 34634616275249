import React from "react";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";

// http://localhost:3000/action?mode=verifyEmail&oobCode=ABC123&apiKey=AIzaSy

// mode - The user management action to be completed
// oobCode - A one-time code, used to identify and verify a request
// apiKey - Your Firebase project's API key, provided for convenience

const Action = (props) => {
  console.log(props.history.location.search);
  // ?mode=verifyEmail&oobCode=1NEF6vmqF1TrREHY0HxANUaBB3g5h3AwCHTx0xJ_sqMAAAGCJqM_PA&apiKey=AIzaSyCgSZJFps-3Od2gp64g_b5P7I3m0waTmAM&lang=en&uid=hWo6YXfGOKVzMy5TIgGwjPSx40H3&userType=mobile
  // Get the action to complete.
  const url = props.history.location.search.split("&").map((item) => {
    return item.split("=")[1];
  });
  console.log(url);
  const mode = url[0];

  // Get the one-time code from the query parameter.
  const actionCode = url[1];
  const uid = url[4];
  const userType = url[5];
  // (Optional) Get the API key from the query parameter.
  // const apiKey = props.location.query.apiKey;

  // Handle the user management action.
  switch (mode) {
    case "resetPassword":
      // Display email recovery handler and UI.
      return <ResetPassword actionCode={actionCode} />;
    case "verifyEmail":
      // Display email verification handler and UI.
      return (
        <VerifyEmail actionCode={actionCode} uid={uid} userType={userType} />
      );
    default:
      // Error: invalid mode.
      return (
        <>
          <div className="bg-gradient-to-r w-full from-bobbllBackground-1 to-bobbllBackground-2">
            <div className="min-h-[100vh]  m-0  grid place-items-center">
              <div
                className="bg-white bg-opacity-60 h-[80vh]  w-11/12 rounded-xl p-10	sm:p-0"
                style={{ backdropFilter: "blur(20px)" }}
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="text-3xl mt-16">
                    <p>Error encountered</p>
                  </div>
                  <div className="text-3xl mt-16">
                    <p>The selected page mode is invalid.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
  }
};

export default Action;
